export const WorkflowType = {
  SecondaryIncreaseDecrease: "IspSecondaryChangeFlow",
  PrimaryBookingRequest: "PrimaryBookingRequestFlow",
  PaymentInstruction: "PaymentFlow",
  ProductIssuanceRequest: "ProductIssuanceFlow",
  TermsheetApproval: "ProductIssuanceIsinAvailableFlow",
  QuarterlyFees: "QuarterliesFlow",
  G2QuarterlyFees: "G2QuarterliesFlow",
  AssetDataUpdate: "AssetDataUpdateFlow",
  OrderAcceptanceTemplate: "OrderAcceptanceTemplateFlow",
  ProductApproval: "ApprovalFlow",
  EntityApproval: "EntityApprovalFlow",
  Coupon: "CouponFlow",
  ProductTermination: "ProductTerminationFlow",
  PartialRedemptionEvent: "PartialRedemptionEventFlow",
  ProductApprovalProcess: "ProductApprovalProcessFlow",
  PayingAgentAccountOpening: "PayingAgentAccountOpeningFlow",
  NoTouchIssuance: "NoTouchIssuanceFlow",
};

export const State = {
  IN_PROGRESS: 0,
  SUSPENDED: 1,
  COMPLETED: 2,
  TERMINATED: 3,
};

export const WorkflowTypeSlug = {
  SecondaryIncreaseDecrease: "secondary-increase-decrease",
  PrimaryBookingRequest: "primary-booking-request",
  PaymentInstruction: "payment-instruction",
  ProductIssuanceRequest: "product-issuance-request",
  TermsheetApproval: "termsheet-approval",
  QuarterlyFees: "quarterly-fees",
  G2QuarterlyFees: "g2-quarterly-fees",
  AssetDataUpdate: "underlying-revaluation",
  OrderAcceptanceTemplate: "order-acceptance-template",
  ProductApproval: "product-approval",
  EntityApproval: "entity-approval",
  Coupon: "coupon",
  ProductTermination: "product-termination",
  PartialRedemptionEvent: "partial-redemption-event",
  ProductApprovalProcess: "product-approval-process",
  PayingAgentAccountOpening: "paying-agent-account-opening",
  NoTouchIssuance: "no-touch-issuance-flow",
};

export const WorkflowTypeColor = {
  [WorkflowType.SecondaryIncreaseDecrease]: "var(--secondary-increase-decrease-color)",
  [WorkflowType.PrimaryBookingRequest]: "var(--primary-booking-request-color)",
  [WorkflowType.PaymentInstruction]: "var(--payment-instruction-color)",
  [WorkflowType.ProductIssuanceRequest]: "var(--product-issuance-request-color)",
  [WorkflowType.TermsheetApproval]: "var(--termsheet-approval-color)",
  [WorkflowType.QuarterlyFees]: "var(--quarterly-fees-color)",
  [WorkflowType.AssetDataUpdate]: "var(--asset-data-update-color)",
  [WorkflowType.ProductApproval]: "var(--approval-color)",
  [WorkflowType.EntityApproval]: "var(--entity-approval-color)",
  [WorkflowType.Coupon]: "var(--coupon-color)",
  [WorkflowType.ProductTermination]: "var(--product-termination-color)",
  [WorkflowType.PartialRedemptionEvent]: "var(--partial-redemption-event-color)",
};

export const EntityFlowTitles = [
  {
    value: "NewTrade",
    label: "New Trade",
  },
  {
    value: "NewUnderlying",
    label: "New Underlying",
  },
];

export const EntityApprovalStatus = {
  NotApproved: 0,
  Approved: 1,
  Rejected: 2,
};

export const FileTypes = [
  { label: "Unknown", value: "NotSet" },
  { label: "Non Disclosure Agreement", value: "NDA" },
  { label: "Passport", value: "Passport" },
  { label: "Utility Bill", value: "UtilityBill" },
  { label: "Final Terms", value: "FinalTerms" },
  { label: "Key Information Document", value: "Kid" },
  { label: "Base prospectus", value: "BaseProspectus" },
  { label: "Other", value: "Other" },
  { label: "Authorized Offeror Agreement", value: "AuthorizedOfferorAgreement" },
  { label: "Issuance Agent Agreement", value: "IssuanceAgentAgreement" },
  { label: "Collateral Agreement", value: "CollateralAgreement" },
  { label: "Control Agreement", value: "ControlAgreement" },
  { label: "Purchase Agreement", value: "PurchaseAgreement" },
  { label: "Subscription Form", value: "SubscriptionForm" },
  { label: "Escrow Agent Agreement", value: "EscrowAgentAgreement" },
  { label: "Other Due Diligence", value: "OtherDueDiligence" },
  { label: "Indicative Terms", value: "IndicativeTerms" },
  { label: "Diagram", value: "Diagram" },
];

export const TaxFillingType = { GermanTaxReturn: 0, USTaxReturn: 1, IBAccountTaxReturn: 2 };
